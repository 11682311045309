<template>
	<div v-loading="tableLoading">
		<div class="nav_co">
			<el-tabs v-model="activeName" @tab-click="tabClickN" class="text_table">
				<el-tab-pane label="营养知识库" v-if="!$store.getters.permissionsName('营养知识库')" name="1"></el-tab-pane>
				<el-tab-pane label="营养品库" v-if="!$store.getters.permissionsName('营养品库')" name="2"></el-tab-pane>
				<el-tab-pane label="营养知识分类设置" v-if="!$store.getters.permissionsName('营养知识分类设置')" name="3"></el-tab-pane>
				<el-tab-pane label="营养品分类设置" v-if="!$store.getters.permissionsName('营养品分类设置')" name="4"></el-tab-pane>
			</el-tabs>
		</div>
		<div id="wrap">
			<div class="content knowledgeAdd">
				<div class="input-from">
					<div class="operation-button">
						<el-button class="export_btn save" @click="save" round>
							<span>保存</span>
						</el-button>
						<span
							class="fanhui"
							@click="
								() => {
									$router.push({ path: '/nutritionSet/nutritionLibrary' })
								}
							"
						>
							<i class="iconfont iconfanhui"></i>
							返回
						</span>
					</div>
				</div>
				<div class="top_title">
					<span></span>
					新增营养品
				</div>
				<div class="box_cont">
					<div class="left">
						<el-form :model="data" :rules="rules" class="clearfix" label-position="right" label-width="auto" ref="form">
							<div class="content_one">
								<el-form-item class="item clearfix" label="一级分类" prop="first_level_uuid" style="margin-left: 3px">
									<label slot="label">一级分类</label>
									<el-select class="o_pp" clearable placeholder="请选择" style="width: 80%" v-model="data.first_level_uuid" @change="leval1Change">
										<el-option :key="item.uuid" :label="item.name" :value="item.uuid" v-for="item in field.options"> </el-option>
									</el-select>
								</el-form-item>
								<el-form-item class="item clearfix" style="margin-left: -5px" label="二级分类" prop="">
									<el-select @change="leval2Change" clearable placeholder="请选择" style="width: 80%" v-model="data.level2">
										<el-option :key="item.uuid" :label="item.name" :value="item.uuid" v-for="item in field.leval2Options"> </el-option>
									</el-select>
								</el-form-item>
							</div>

							<div class="content_one">
								<el-form-item class="item clearfix" label="营养品名称" prop="name">
									<el-input maxlength="30" placeholder="字数在30以内哦~" v-model="data.name"> </el-input>
								</el-form-item>
								<el-form-item class="item clearfix" label="英文名称" prop="en_name">
									<el-input maxlength="60" placeholder="字数在60以内哦~" v-model="data.en_name"> </el-input>
								</el-form-item>
							</div>

							<div class="content_one">
								<el-form-item class="item clearfix" label="标签" prop="label">
									<label slot="label">标签&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
									<el-input maxlength="15" placeholder="字数在15以内哦~" v-model="data.label"> </el-input>
								</el-form-item>
								<el-form-item class="item clearfix" label="厂家" prop="manufacturer">
									<label slot="label">厂家&nbsp;&nbsp;</label>
									<el-input maxlength="30" placeholder="字数在30以内哦~" v-model="data.manufacturer"> </el-input>
								</el-form-item>
							</div>
							<div class="content_one">
								<el-form-item class="item clearfix" label="规格" prop="specifications">
									<label slot="label">规格&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
									<el-input maxlength="30" placeholder="字数在30以内哦~" v-model="data.specifications"> </el-input>
								</el-form-item>
								<el-form-item class="item clearfix" label="口味" prop="flavor">
									<label slot="label">口味&nbsp;&nbsp;</label>
									<el-input maxlength="30" placeholder="字数在30以内哦~" v-model="data.flavor"> </el-input>
								</el-form-item>
							</div>
							<el-form-item class="item clearfix guan" label="食用方法" prop="use_method">
								<label slot="label">食用方法</label>
								<el-input maxlength="100" class="edible" v-model="data.use_method" placeholder="字数在100以内哦~"> </el-input>
							</el-form-item>
							<el-form-item class="item clearfix guan" label="适用场景" prop="scenes">
								<label slot="label">适用场景</label>
								<el-input maxlength="100" placeholder="字数在100以内哦~" class="edible" v-model="data.scenes"> </el-input>
							</el-form-item>
							<el-form-item class="item clearfix guan" label="主要配料" prop="ingredients">
								<label slot="label">主要配料</label>
								<el-input maxlength="100" class="edible" placeholder="字数在100以内哦~" v-model="data.ingredients"> </el-input>
							</el-form-item>
						</el-form>
					</div>

					<div class="right">
						<div style="text-align: center">
							<p style="margin-bottom: 10px; margin-left: -90%"><span style="color: red">*</span>缩略图</p>
							<div style="border: 1px dashed #ccc">
								<img
									:src="
										imgData
											? imgData
											: 'https://storage.lynnn.cn/assets/markdown/public/pictures/2021/10/7b5ed969a1ba281879a8be30fee4d256f36ff54d.png?sign=4c1e0114861b4c8f2b9b11ffdf4f3166&t=61603213'
									"
									height="153px"
									width="250px"
									alt
								/>
							</div>
							<div class="upload-wrap upl">
								<input type="file" class="wen" style="cursor: pointer" @change="changeFile($event)" name="pic" id="pic" accept="image/gif, image/jpeg ,image/png," />
							</div>
							<p style="color: #666666">支持上传jpg、png、jpeg 文件,且不超过800kb</p>
						</div>
					</div>
				</div>
				<!-- 内容 -->
				<div class="test_content">
					<div class="con_t">
						<span style="color: red">*</span>
						产品介绍
					</div>
					<div class="test_editor" id="editor" ref="editorElem" style="width: 100%"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import vueEditorWrap from 'vue-ueditor-wrap'
import E from 'wangeditor'

export default {
	data() {
		var validatePass = (rule, value, callback) => {
			if (this.data.level2 === '') {
				callback(new Error('请选择二级分类'))
			} else {
				callback()
			}
		}
		return {
			// loading: true, // 加载中
			activeName: '2',
			editorContent: '',
			field: {
				options: [],
				leval3Options: [],
				leval2Options: [],
				value: '',
			},
			input: '',
			tableLoading: true,
			data: {
				title: '',
				name: '',
				field: '',
				level2: '',
				level4: '',
				keyword: '',
				synopsis: '',
				//****** */
				ingredients: '',
				en_name: '',
				label: '',
				specifications: '',
				manufacturer: '',
				flavor: '',
				use_method: '',
				scenes: '',
			},
			imageFile: null,
			editor: null,
			imgData: '',
			sendImgData: '',
			/*表单校验*/
			rules: {
				keyword: {
					...this.$rules.noEmpty,
					message: '文章关键词不能为空',
					max: 30,
				},
				name: { ...this.$rules.noEmpty, message: '名称不能为空' },
				synopsis: { ...this.$rules.noEmpty, message: '概述不能为空' },
				content: { ...this.$rules.noEmpty, message: '内容不能为空' },
				label: { ...this.$rules.noEmpty, message: '标签不能为空' },
				first_level_uuid: { ...this.$rules.noEmpty, message: '请选择分类' },
				twofield: {
					validator: validatePass,
					trigger: 'change',
					required: true,
				},
			},
			editor_value: '',
			myConfig: {
				// 编辑器不自动被内容撑高
				autoHeightEnabled: false,
				//是否启用元素路径
				elementPathEnabled: false,
				// 初始容器高度
				initialFrameHeight: 400,
				// 初始容器宽度
				initialFrameWidth: '95%',
				// 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
				// serverUrl: 'http://35.201.165.105:8000/controller.php',
				// UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
				UEDITOR_HOME_URL: './static/static1/',
			},
			ueditor: null,
		}
	},
	components: {
		VueUeditor: vueEditorWrap,
	},

	created() {
		this.getSelectData()
	},
	mounted() {
		this.editor = new E(this.$refs.editorElem)
		// 编辑器的事件，每次改变会获取其html内容
		this.editor.config.onchange = html => {
			this.editorContent = html
		}
		this.editor.config.zIndex = 1000
		this.editor.config.uploadImgShowBase64 = true
		this.editor.config.menus = [
			// 菜单配置
			'head', // 标题
			'bold', // 粗体
			'fontSize', // 字号
			'fontName', // 字体
			'italic', // 斜体
			'underline', // 下划线
			'strikeThrough', // 删除线
			'foreColor', // 文字颜色
			'backColor', // 背景颜色
			'link', // 插入链接
			'list', // 列表
			'justify', // 对齐方式
			'quote', // 引用
			'image', // 插入图片
			'table', // 表格
			'code', // 插入代码
		]
		this.editor.create() // 创建富文本实例
		setTimeout(() => {
			this.tableLoading = false
		}, 1500)
	},
	methods: {
		/*导航*/
		tabClickN() {
			sessionStorage.removeItem('arch_search')

			if (this.activeName == '1') {
				this.$router.push({
					path: '/nutritionSet/knowledgeList',
					query: { type: 1 },
				})
			} else if (this.activeName == '2') {
				this.$router.push({
					path: '/nutritionSet/nutritionLibrary',
					query: { type: 2 },
				})
			} else if (this.activeName == '3') {
				this.$router.push({
					path: '/nutritionSet/knowledgeSet',
					query: { type: 3 },
				})
			} else if (this.activeName == '4') {
				this.$router.push({
					path: '/nutritionSet/nutritionLibrarySet',
					query: { type: 4 },
				})
			}
		},
		ready(editorInstance) {
			this.ueditor = editorInstance
			this.getSelectData()
		},

		levelChange(val) {
			this.data.level2 = ''
			this.field.options.forEach((i, k) => {
				if (i.uuid === val) {
					this.field.leval2Options = i.children
				}
			})
		},
		// 查看
		getData() {
			this.$axios
				.post(
					'/p/nutrition/read',
					this.$qs({
						uuid: this.$route.query.uuid,
					})
				)
				.then(res => {
					if (res.data.code === 0) {
						this.data = {
							...this.data,
							...res.data.data,
						}
						if (res.data.data.first_level_name !== '') {
							let leval1Change = val => {
								return new Promise((resolve, reject) => {
									this.field.options.forEach((i, k) => {
										if (i.uuid === val) {
											this.field.leval2Options = i.children
										}
									})
									resolve()
								})
							}
							leval1Change(this.data.first_level_uuid).then(() => {
								this.data.level2 = this.data.second_level_uuid
							})
						} else {
							this.data.field = res.data.article_cate_uuid
						}
						if (this.data.level === 4) {
							this.data.level3 = this.article_cate_uuid
						}
						try {
							this.ueditor.setContent(decodeURI(res.data.content))
						} catch (err) {
							if (this.ueditor) {
								this.ueditor.setContent(res.data.content)
							}
							console.log(err)
						}
						// this.editor.txt.html(res.data.content);
						this.imgData = `${res.data.data.img_host}${res.data.data.img_path}`
						this.sendImgData = `${res.data.data.img_host}${res.data.data.img_path}`
						this.$set(this, 'sendImgData', res.data.data.img_path)
						this.editorContent = res.data.data.synopsis
						this.editor.txt.html(res.data.data.synopsis)
					}
				})
		},

		changeFile(e) {
			function getObjectURL(file) {
				var url = null
				if (window.createObjectURL != undefined) {
					// basic
					url = window.createObjectURL(file)
				} else if (window.URL != undefined) {
					// mozilla(firefox)
					url = window.URL.createObjectURL(file)
				} else if (window.webkitURL != undefined) {
					// webkit or chrome
					url = window.webkitURL.createObjectURL(file)
				}
				return url
			}

			let imgData = e.target.files[0]
			this.imgFile = imgData
			this.imgData = getObjectURL(imgData)
		},
		leval1Change(val) {
			this.field.options.forEach((i, k) => {
				if (i.uuid === val) {
					this.field.leval2Options = i.children
				}
			})
			this.data.level4 = ''
			this.data.level2 = ''
			this.field.leval3Options = []
		},
		leval2Change(val) {
			this.field.leval2Options.forEach((i, k) => {
				if (i.uuid === val) {
					this.field.leval3Options = i.children ? i.children : []
				}
			})
			this.data.level4 = ''
		},

		save() {
			let submit = () => {
				let data = {
					// content: encodeURI(),
					// keyword: this.data.keyword,
					name: this.data.name,
					// content: this.editorContent,
					synopsis: this.editorContent,
					article_cate_uuid: this.uuid,
					ingredients: this.data.ingredients,
					en_name: this.data.en_name,
					label: this.data.label,
					specifications: this.data.specifications,
					manufacturer: this.data.manufacturer,
					flavor: this.data.flavor,
					use_method: this.data.use_method,
					scenes: this.data.scenes,
				}
				let childrenShow = true
				if (this.data.first_level_uuid !== '' && this.data.first_level_uuid !== undefined) {
					let name = ''
					this.field.options.forEach((i, k) => {
						if (i.uuid === this.data.first_level_uuid) {
							name = i.name
						}
					})
					// data.name = name
					data.article_cate_uuid = this.data.first_level_uuid
				}
				if (this.data.level2 !== '' && this.data.level2 !== undefined) {
					let name = ''
					this.field.leval2Options.forEach((i, k) => {
						if (i.uuid === this.data.level2) {
							name = i.name
						}
					})
					// data.name = name
					data.article_cate_uuid = this.data.level2
				}
				if (this.data.level4 !== undefined && this.data.level4 !== '') {
					let name = ''
					this.field.leval3Options.forEach((i, k) => {
						if (i.uuid === this.data.level4) {
							name = i.name
						}
					})
					data.article_cate_uuid = this.data.level4
					// data.name = name
				}

				let formData = new FormData()
				for (let i in data) {
					formData.append(i, data[i])
				}
				if (!childrenShow) {
					return false
				}
				if (this.$route.query.uuid) {
					formData.append('uuid', this.$route.query.uuid)
					formData.append('img_path', this.imgFile === undefined ? this.sendImgData : this.imgFile)
					this.$axios.post('/p/nutrition/update', formData).then(res => {
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.message,
							})
							this.$router.push({
								path: '/nutritionSet/nutritionLibrary',
								query: {},
							})
						} else {
							this.$message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
				} else {
					formData.append('img_path', this.imgFile)
					this.$axios
						.post('/p/nutrition/add', formData)
						.then(res => {
							if (res.data.code == 0) {
								this.$message({
									type: 'success',
									message: res.data.message,
								})
								this.$router.push({
									path: '/nutritionSet/nutritionLibrary',
									query: {},
								})
							} else {
								this.$message({
									type: 'error',
									message: res.data.message,
								})
							}
						})
						.catch(error => {
							console.log(error)
						})
				}
			}
			this.$refs['form'].validate(valid => {
				if (valid) {
					submit()
				} else {
					return false
				}
			})
		},

		getSelectData() {
			let url = '/p/articleCate/all'
			this.$axios
				.post(url, this.$qs({ type: 2 }))
				.then(res => {
					if (res.data.code === 0) {
						this.field.options.unshift({
							name: '全部',
							type: 0,
						})
						this.field.options = res.data.data
					}
				})
				.then(() => {
					if (this.$route.query.uuid) {
						this.getData()
					}
				})
		},
	},
}
</script>

<style lang="scss" scoped>
#wrap {
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	& /deep/ .el-form-item__error {
		top: 40px;
	}
}

.child-top {
	overflow: hidden;
	background: #f4f6f9;
	padding: 10px 20px 0 20px;
	border-radius: 2px 2px 0px 0px;
	border: 1px solid rgba(227, 227, 227, 1);
	border-bottom: 0;

	.operation-button {
		float: left;
		margin-bottom: 10px;

		.el-button.el-button--default {
			width: 84px;
			padding: 11px 10px;
			float: left;
			font-size: 13px;
		}

		.el-button.el-button--default:nth-child(even) {
			border-radius: 0 4px 4px 0;
		}

		.el-button.el-button--default:nth-child(odd) {
			border-radius: 4px 0 0 4px;
			margin-left: 10px;
		}
	}
}

.content {
	width: 100%;
	/*height: 100%;*/
	box-sizing: border-box;
	padding: 0 24px 0 24px;
	/*margin-bottom: 50px;*/
	overflow: auto;
	flex: 1;

	.item {
		width: 45%;
		float: left;
		margin-right: 3%;
		margin-top: 25px;
		height: 20px;
		margin-bottom: 25px;
		padding-left: 34px;
		span {
			float: left;
			width: 20%;
			line-height: 150px;
		}

		.el-input {
			float: left;
			width: 80%;
		}
	}
}

.content /deep/ .el-form {
	.el-form-item {
		/*width: 50%;*/
		margin-right: 0;
		display: flex;

		.el-form-item__content {
			flex: 1;
			margin-left: 0 !important;

			.el-select {
				width: 80% !important;
			}

			.el-input {
				// width: 100% !important;
			}

			.w-e-text-container {
				height: 200px !important;

				.w-e-text {
					overflow: auto;
				}
			}
		}
	}
	.suoluetu {
		height: unset;

		.imgWrap {
			width: 225px;
			height: 200px;
			overflow: hidden;
			text-align: center;
			line-height: 150px;
			position: relative;
			background-image: url('http://iph.href.lu/879x200?text=%2B');
			cursor: pointer;
			margin-bottom: 20px;

			span {
				line-height: 150px;
				color: #a9a9a9;
				font-size: 40px;
				width: 100%;
			}

			.el-image {
				position: absolute;
				background: rgba(0, 0, 0, 0);
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 10;
			}

			.upload-wrap {
				width: 100%;
				height: 100%;
				position: relative;
				background: rgba(0, 0, 0, 0);
				text-align: center;
				z-index: 20;

				span {
					line-height: 150px;
					color: #a9a9a9;
					font-size: 40px;
					width: 100%;
					position: relative;
					z-index: 10;
				}

				input {
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					opacity: 0;
					z-index: 20;
				}

				input:hover {
					cursor: crosshair;
				}
			}

			.upload-wrap:hover {
				background: rgba(0, 0, 0, 0.2);
			}

			.upload-wrap:hover span {
				z-index: 30;
			}
		}
	}
	.el-form-item:nth-child(even) {
		// float: right;
	}

	label {
		padding-right: 20px;
	}
}

#wangEditor {
	margin-top: 100px;
}

.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 120px;
	height: 120px;
	line-height: 120px;
	text-align: center;
}

.avatar {
	width: 120px;
	height: 120px;
	display: block;
}

.el-textarea__inner {
	height: 200px;
	display: inline-block;
}
.textarea {
	.el-textarea__inner {
		height: 200px;
		display: inline-block;
	}
}
// 测试评价
.test_content {
	background: #fff;
	height: auto;
	padding: 40px 0px 30px 50px;
	// margin-top: 10px;
	border-radius: 4px;
	.top_title {
		margin-left: 0;
	}
	.test_editor {
		width: 90% !important;
		// margin-top: 40px;
		// margin-left: 35px;
		//  width: 1100px !important;
		margin-left: 78px;
		height: 400px;
	}
	.look_editor {
		overflow: auto;
		border: 1px solid #f5f7fa;
		border-radius: 5px;
		padding: 20px;
		box-sizing: border-box;
		/* table 样式 */
		table {
			border-top: 1px solid #ccc;
			border-left: 1px solid #ccc;
		}
		table td,
		table th {
			border-bottom: 1px solid #ccc;
			border-right: 1px solid #ccc;
			padding: 3px 5px;
		}
		table th {
			border-bottom: 2px solid #ccc;
			text-align: center;
		}

		/* blockquote 样式 */
		blockquote {
			display: block;
			border-left: 8px solid #d0e5f2;
			padding: 5px 10px;
			margin: 10px 0;
			line-height: 1.4;
			font-size: 100%;
			background-color: #f1f1f1;
		}

		/* code 样式 */
		code {
			display: inline-block;
			*display: inline;
			*zoom: 1;
			background-color: #f1f1f1;
			border-radius: 3px;
			padding: 3px 5px;
			margin: 0 3px;
		}
		pre code {
			display: block;
		}

		/* ul ol 样式 */
		ul,
		ol {
			margin: 10px 0 10px 20px;
		}
	}
}
// 内容
.knowledgeAdd {
	height: 100%;
	background-color: #fff;
}
.child-top {
	justify-content: space-around !important;
	.input-from {
		.searchBtn {
			margin-top: 10px;
		}
	}
	.operation-button {
		// float: left;
		margin-bottom: 10px;
		margin-top: 10px;
	}
}
.operation-button {
	padding-top: 30px;
	width: 300px;
	margin-left: 80%;
	.save {
		width: 120px;
		margin-right: 70px;
		border-radius: 20px;
		background-color: #484ee9;
		color: #fff;
	}
}
.top_title {
	// padding-top: 30px;
	display: flex;
	align-items: center;
	font-size: 18px;
	color: #333;
	font-weight: 500;
	font-family: PingFangSC-Medium, PingFang SC;
	margin-left: 30px;
	span {
		display: inline-block;
		width: 6px;
		height: 20px;
		background: #0055e9;
		border-radius: 3px;
		margin-right: 10px;
	}
}
.el-pagination {
	padding-bottom: 10px;
}
.el-table__row {
	width: 100%;
}
.child-a {
	width: 300px;
	margin-left: 40px;
	span {
		margin-right: 10px;
	}
}
.child-b {
	width: 77%;
}
.form-group {
	width: 77%;
	#user_mes {
		width: 600px;
		height: 100px;
		resize: none;
	}
}
.box_cont {
	width: 100%;
	display: flex;
	.left {
		// width: 68%;
		width: 74%;
	}
	.right {
		width: 20%;
		margin-left: 20px;
		padding-top: 30px;
	}
}
.guan {
	width: 100% !important;
}
// .gai {
//   width: 850px !important;
// }
/deep/ .clearfix {
	.el-input .el-input__inner {
		border-radius: 0;
		border-right-width: 0;
		border: none;
		border-bottom: 2px solid #ccc;
	}

	.el-input .el-input__inner:focus {
		border-color: #0055e9;
	}

	.el-select {
		float: left;
	}

	.el-select .el-input__inner {
		border-right-width: 0;
		border: none;
		border-radius: 0;
		border-bottom: 2px solid #ccc;
	}

	.el-select .el-input__inner:focus {
		border-color: #0055e9;
	}

	.el-select .el-input__inner:focus {
		border-right-width: 1px;
	}

	.bot_content {
		p {
			display: flex;
			align-items: center;
			width: 100%;

			i {
				font-size: 22px;
			}

			span {
				font-size: 18px;
			}
		}
	}
}
.con_t {
	font-size: 17px;
	margin-left: -22px;
	margin-bottom: -20px;
}

.wen {
	margin-left: 50px;
}
.nav_co {
	position: relative;
	ul {
		position: absolute;
		top: 0;
		left: 30px;
		display: flex;
		width: 40%;
		li {
			z-index: 99;
			width: 100%;
			height: 55px;
			opacity: 0;
		}
	}
}
.o_pp {
	margin-left: -7px !important;
}
.upl {
	opacity: 0;
	margin-top: -150px;
	margin-left: -50px;
	z-index: 99;
	input {
		height: 150px;
	}
}
.format {
	margin-top: 10px;
	margin-left: 50px;
}
.edible {
	width: 89% !important;
}
.content_one {
	display: flex;
	justify-content: space-between;
}
</style>
